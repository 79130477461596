.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-item.nav-link.active {
  background-color: blue;
  color: white;
}

.rotateM16 {
  -webkit-transform: rotate(-16deg);
  -moz-transform: rotate(-16deg);
  -o-transform: rotate(-16deg);
  -ms-transform: rotate(-16deg);
  transform: rotate(-16deg);
}

.rotateP16 {
  -webkit-transform: rotate(16deg);
  -moz-transform: rotate(16deg);
  -o-transform: rotate(16deg);
  -ms-transform: rotate(16deg);
  transform: rotate(16deg);
}

img.active {
  padding: 2px;
  border: 2px solid gray;
}

.country-flag img {
  position: initial;
}
