body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logopicture {
  position: relative;
  text-align: center;
  color: white;
}

h1#logo {
  position: absolute;
  top: 50px;
  left: 30px;
  font-size: 50px;
  color: white;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

span#sublogo {
  position: absolute;
  top: 100px;
  left: 60px;
  font-size: 20px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.pict-sub {
  text-align: left;
  font-size: 20px;
  color: rgb(28, 30, 196);
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
