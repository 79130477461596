.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-item.nav-link.active {
  background-color: blue;
  color: white;
}

.rotateM16 {
  -webkit-transform: rotate(-16deg);
  transform: rotate(-16deg);
}

.rotateP16 {
  -webkit-transform: rotate(16deg);
  transform: rotate(16deg);
}

img.active {
  padding: 2px;
  border: 2px solid gray;
}

.country-flag img {
  position: static;
  position: initial;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logopicture {
  position: relative;
  text-align: center;
  color: white;
}

h1#logo {
  position: absolute;
  top: 50px;
  left: 30px;
  font-size: 50px;
  color: white;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

span#sublogo {
  position: absolute;
  top: 100px;
  left: 60px;
  font-size: 20px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.pict-sub {
  text-align: left;
  font-size: 20px;
  color: rgb(28, 30, 196);
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

